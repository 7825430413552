<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Régions Catalogue</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'regionCatalogueCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter une région catalogue
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Position
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(regionCatalogue, regionCatalogueIndex) in regionsCatalogue"
              :key="'e' + regionCatalogueIndex"
            >
              <td class="font-weight-bold">
                {{ resolveAttrValue(regionCatalogue, 'name') }}
              </td>
              <td class="text-center">
                {{ regionCatalogue.position }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'regionCatalogueDetails', params: { regionCatalogueId: regionCatalogue.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteRegionCatalogueDialog(regionCatalogue)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!regionsCatalogue.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteRegionCatalogueDialog
        :key="Date.now()"
        :delete-region-catalogue-dialog-state="deleteRegionCatalogueDialogState"
        :region-catalogue="regionCatalogueToDelete"
        @closeDeleteRegionCatalogueDialog="closeDeleteRegionCatalogueDialog($event)"
      ></DeleteRegionCatalogueDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import DeleteRegionCatalogueDialog from '@/components/Dialog/LocationDialog/RegionCatalogue/DeleteRegionCatalogueDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteRegionCatalogueDialog,
    Loader
  },
  data: () => ({
    regionsCatalogue: [],
    regionCatalogueToDelete: null,
    deleteRegionCatalogueDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getRegionCatalogues()
  },
  methods: {
    getRegionCatalogues () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/location/regions-catalogue', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.regionsCatalogue = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    openDeleteRegionCatalogueDialog (regionCatalogue) {
      this.deleteRegionCatalogueDialogState = true
      this.regionCatalogueToDelete = regionCatalogue
    },
    closeDeleteRegionCatalogueDialog (deleteState) {
      if (deleteState === true) {
        this.regionsCatalogue = this.regionsCatalogue.filter(element => element.id !== this.regionCatalogueToDelete.id)
      }

      this.deleteRegionCatalogueDialogState = false
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    }
  }
}
</script>

